Vue.component("header-component", {
    props: ['homePage'],
    data: function () {
        return {
            scrolled: false,
            switchedOff: false
        }
    },
    mounted: function () {
        let that = this;

        window.addEventListener('scroll', function () {
            that.watchScrollPosition();
        });
        this.watchScrollPosition();

        if (typeof(Storage) === 'undefined' || localStorage.siteVisited || ('backoffice' in document.body.dataset)) {
            this.switchedOff = false;
        }
        else if (this.homePage === "true") {
            this.switchedOff = true;
        }

        localStorage.siteVisited = true;
    },
    methods: {
        watchScrollPosition: function () {
            let that = this;
            const scrollTop = Math.max(window.pageYOffset, document.documentElement.scrollTop, document.body.scrollTop);

            if (scrollTop > 50) {
                that.scrolled = true;
            } else {
                that.scrolled = false;
            }
        }
    },
});